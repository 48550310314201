<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Equipos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Equipos</option>
                    <option :value="2">Documentos</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Placa</label>
                <input
                  type="text"
                  id="placa"
                  placeholder="Placa"
                  label="Placa"
                  v-model="filtros.placa"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Tipo de equipo</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_equipo"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_equipo in listasForms.tipos_equipos"
                    :key="tipo_equipo.id"
                    :value="tipo_equipo.id"
                  >
                    {{ tipo_equipo.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Modelo</label>
                <input
                  type="number"
                  id="Modelos"
                  placeholder="Modelo"
                  v-model="filtros.modelo"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Marca</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.marca"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="marca in listasForms.marcas"
                    :key="marca.numeracion"
                    :value="marca.numeracion"
                  >
                    {{ marca.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Estado</label>
                <select
                  id="estado"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.estado"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Propiedad</label>
                <select
                  id="propiedad"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.propiedad"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="propiedad in listasForms.propiedad"
                    :key="propiedad.numeracion"
                    :value="propiedad.numeracion"
                  >
                    {{ propiedad.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Tipo de Combustible</label>
                <select
                  id="combustible"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.combustible"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="combustible in listasForms.combustible"
                    :key="combustible.numeracion"
                    :value="combustible.numeracion"
                  >
                    {{ combustible.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('admin.equipos.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "SolicitudPivotTable",
  components: {
    PivotTableComponent,
    Loading,
  },
  data() {
    return {
      cargando: false,
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      filtros: {
        placa: null,
        tipo_equipo: null,
        modelo: null,
        marca: null,
        estado: null,
        propiedad: null,
        combustible: null,
      },
      tipo_consulta: null,
      listasForms: {
        tipos_equipos: [],
        marcas: [],
        estados: [],
        propiedad: [],
        combustible: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Admin/Equipos");
    },
    /*     async getTiposVehiculos() {
      await axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    },
 */

    getTiposEquipos(tipo_equipos) {
      let me = this;
      var url = "/api/lista/121";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 1,
            not_id: tipo_equipos,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipos_equipos = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getEstado() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    async getTiposPropiedad() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.propiedad = response.data;
      });
    },
    async getCombustibles() {
      await axios.get("/api/lista/24").then((response) => {
        this.listasForms.combustible = response.data;
      });
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/equipos/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getTiposEquipos();
    await this.getMarcas();
    await this.getEstado();
    await this.getTiposPropiedad();
    await this.getCombustibles();
    this.cargando = false;
  },
};
</script>
